import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const CtaSection = ({ title1, text, link1, link1text, link2, link2text }) => {
  return (
    <div className="bg-gray-200">
      <div className="max-w-screen-xl gap-8 px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <div>
          <h2 className="mb-4 text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            {title1}
          </h2>
          <p className="mb-6 prose prose-lg text-gray-700 lg:max-w-none lg:mb-0">
            {text}
          </p>
        </div>
        <div className="flex mt-8 lg:flex-shrink-0 lg:mt-0">
          <div className="inline-flex rounded-md shadow">
            <Link
              to={link1}
              className="justify-center px-5 py-3 text-base font-medium leading-6 text-center text-white transition duration-150 ease-in-out bg-blue-500 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:shadow-outline"
            >
              {link1text}
            </Link>
          </div>
          {link2 && link2text && (
            <div className="inline-flex ml-3 rounded-md shadow">
              <Link
                to={link2}
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-indigo-600 transition duration-150 ease-in-out bg-white border border-transparent rounded-md hover:text-indigo-500 focus:outline-none focus:shadow-outline"
              >
                {link2text}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

CtaSection.propTypes = {
  title1: PropTypes.string.isRequired,
  text: PropTypes.string,
  link1: PropTypes.string.isRequired,
  link1text: PropTypes.string.isRequired,
  link2: PropTypes.string,
  link2text: PropTypes.string,
}

export default CtaSection
