import React from "react"

const ContContentTwoColumns = ({ children }) => {
  return (
    <div className="px-4 my-16 overflow-hidden bg-white xl:my-36 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-max-content lg:max-w-7xl">
        <div className="relative">
          <svg
            className="absolute top-0 right-0 hidden -mt-20 -mr-20 md:block"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
            />
          </svg>
          <svg
            className="absolute bottom-0 left-0 hidden -mb-20 -ml-20 md:block"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
            />
          </svg>
          <div className="relative md:bg-white md:p-6">
            <section className="mb-8 lg:grid lg:grid-cols-2 lg:gap-6">
              <div className="mb-6 text-gray-700 lg:max-w-none lg:mb-0">
                {children.length > 0 ? children[0] : children}
              </div>
              {children[1] && (
                <div className="text-gray-700">{children[1]}</div>
              )}
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContContentTwoColumns
