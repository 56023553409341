import React from "react"

const H1 = ({ children, className }) => {
  return (
    <h1
      className={`text-4xl font-extrabold leading-relaxed text-gray-900 sm:leading-relaxed lg:leading-relaxed sm:text-3xl lg:text-4xl ${className ? className : ""}`}
    >
      {children}
    </h1>
  )
}

export default H1

