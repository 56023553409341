import React from 'react'

const P = ({ children, className }) => {
    return (
      <p
        className={`ttext-lg leading-relaxed text-gray-700 ${className ? className : ""}`}
      >
        {children}
      </p>
    )
}
  
export default P
  