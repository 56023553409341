import React from "react"
import PropTypes from "prop-types"
import H2 from "../typography/H2"

const ContTextbox = ({ title, children, preheader }) => {
  return (
    <div className="mb-8">
      <div className="relative z-10 mb-8 md:mb-2">
        <div className="mb-5">
          {preheader && (
            <p className="font-semibold leading-6 tracking-wide text-indigo-500 uppercase">
              {preheader}
            </p>
          )}
          <H2 className={`${
              preheader ? "mt-2" : ""
            }`}>{title}</H2>

        </div>
      </div>
      <div className="prose">
        {children}
      </div>
    </div>
  )
}

ContTextbox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  preheader: PropTypes.string,
}

export default ContTextbox
