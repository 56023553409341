import React from "react"

const H2 = ({ children, className }) => {
  return (
    <h2
      className={`text-2xl font-extrabold leading-relaxed text-gray-900 sm:leading-relaxed sm:text-3xl ${className ? className : ""}`}
    >
      {children}
    </h2>
  )
}

export default H2

