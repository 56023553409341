import React from "react"
import PropTypes from "prop-types"
import Button from "./Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Container from "../containers/Container"

const Header = ({ link, linktext, link2, link2text, image, alt, children }) => {
  let buttons

  if (link && !link2) {
    buttons = (
      <div className="flex flex-col sm:flex-row sm:justify-center lg:justify-start">
        <div className="inline-flex mb-3 sm:mb-0 sm:mr-3">
          <Button to={link} text={linktext} primary />
        </div>
      </div>
    )
  }

  if (link && link2) {
    buttons = (
      <div className="flex flex-col sm:flex-row sm:justify-center lg:justify-start">
        <div className="inline-flex mb-3 sm:mb-0 sm:mr-3">
          <Button to={link} text={linktext} primary />
        </div>
        <div className="inline-flex">
          <Button to={link2} text={link2text} white />
        </div>
      </div>
    )
  }

  return (
    <div className="relative overflow-hidden bg-white">
      <Container>
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <div className="mb-6">{children}</div>
            {buttons}
          </div>
          <div className="relative mt-12 sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <svg
              className="absolute top-0 origin-top transform scale-75 -translate-x-1/2 -translate-y-8 left-1/2 sm:scale-100 lg:hidden"
              width={640}
              height={784}
              fill="none"
              viewBox="0 0 640 784"
            >
              <defs>
                <pattern
                  id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                  x={118}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                y={72}
                width={640}
                height={640}
                className="text-gray-50"
                fill="#ffffff"
              />
              <rect
                x={118}
                width={404}
                height={784}
                fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"
              />
            </svg>
            <div className="relative w-full mx-auto text-center">
              <GatsbyImage
                image={getImage(image)}
                className={`rounded-lg shadow-lg`}
                style={{ maxWidth: "90%", zIndex: "1" }}
                alt={alt}
                title={alt}
              />

              <div
                className={`absolute block w-full rounded-lg bg-gradient-to-r from-indigo-500 to-blue-500`}
                style={{ height: "50%", left: "0%", top: "5%", zIndex: "0" }}
              ></div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  linktext: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export default Header
